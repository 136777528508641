// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-accessibility-tsx": () => import("./../../../src/pages/Accessibility.tsx" /* webpackChunkName: "component---src-pages-accessibility-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-business-details-page-tsx": () => import("./../../../src/pages/BusinessDetailsPage.tsx" /* webpackChunkName: "component---src-pages-business-details-page-tsx" */),
  "component---src-pages-company-page-tsx": () => import("./../../../src/pages/CompanyPage.tsx" /* webpackChunkName: "component---src-pages-company-page-tsx" */),
  "component---src-pages-disclaimer-page-tsx": () => import("./../../../src/pages/DisclaimerPage.tsx" /* webpackChunkName: "component---src-pages-disclaimer-page-tsx" */),
  "component---src-pages-discounting-info-tsx": () => import("./../../../src/pages/discounting_info.tsx" /* webpackChunkName: "component---src-pages-discounting-info-tsx" */),
  "component---src-pages-finance-process-tsx": () => import("./../../../src/pages/FinanceProcess.tsx" /* webpackChunkName: "component---src-pages-finance-process-tsx" */),
  "component---src-pages-get-in-touch-tsx": () => import("./../../../src/pages/get_in_touch.tsx" /* webpackChunkName: "component---src-pages-get-in-touch-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-loan-page-tsx": () => import("./../../../src/pages/LoanPage.tsx" /* webpackChunkName: "component---src-pages-loan-page-tsx" */),
  "component---src-pages-login-page-tsx": () => import("./../../../src/pages/LoginPage.tsx" /* webpackChunkName: "component---src-pages-login-page-tsx" */),
  "component---src-pages-long-term-info-tsx": () => import("./../../../src/pages/long_term_info.tsx" /* webpackChunkName: "component---src-pages-long-term-info-tsx" */),
  "component---src-pages-review-tsx": () => import("./../../../src/pages/review.tsx" /* webpackChunkName: "component---src-pages-review-tsx" */),
  "component---src-pages-short-term-info-tsx": () => import("./../../../src/pages/short_term_info.tsx" /* webpackChunkName: "component---src-pages-short-term-info-tsx" */),
  "component---src-pages-sme-finance-tsx": () => import("./../../../src/pages/SmeFinance.tsx" /* webpackChunkName: "component---src-pages-sme-finance-tsx" */),
  "component---src-pages-upload-documents-page-tsx": () => import("./../../../src/pages/UploadDocumentsPage.tsx" /* webpackChunkName: "component---src-pages-upload-documents-page-tsx" */),
  "component---src-pages-user-details-page-tsx": () => import("./../../../src/pages/UserDetailsPage.tsx" /* webpackChunkName: "component---src-pages-user-details-page-tsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

